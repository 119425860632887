import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { graphql, StaticQuery } from 'gatsby';

const About = () => (
  <StaticQuery
    query={graphql`
      query About {
        allAboutJson {
          nodes {
            about
          }
        }
      }
    `}
    render={(data) => (
      <Container className="about-content">
        <Row className="justify-content-center">
          <Col md={8} className="pt-5 pb-5">
            <h1>O NÁS</h1>
            {data.allAboutJson.nodes[0].about}
          </Col>
        </Row>
      </Container>
    )}
  />
);

export default About;
