import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import logo from '../images/logo.png';
import background from '../images/cukolada-photo.jpg';

const Hero = () => (
  <section style={{ backgroundImage: `url(${background})` }} className="hero-image">
    <Container>
      <Row>
        <Col md={12}>
          <img className="logo-hero-image" src={logo} alt="Logo" />
        </Col>
      </Row>
    </Container>
  </section>
);

export default Hero;
