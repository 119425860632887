import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/hero';
import About from '../components/about';
import Instagram from '../components/instagram';

const IndexPage = () => (
  <Layout>
    <SEO title="Domov" />
    <Hero />
    <About />
    {/*<Instagram />*/}
  </Layout>
);

export default IndexPage;
